html,
body,
#root {
  height: 100vh;
}

html,
body {
  overflow: hidden;
  font-family: "Lato", "Helvetica", "Arial", sans-serif;
  margin: 0;
}

#root {
  overflow: auto;
  position: relative;
}

.properties-list {
  width: 100%;
  font-size: 14px;
}

.properties-list__item {
  display: flex;
  flex-direction: row;
}

.properties-list__item-title {
  flex-basis: 250px;
  padding: 8px;
  text-align: right;
  color: #6c757d;
  flex-shrink: 0;
}

.properties-list__item-value {
  padding: 8px;
}
