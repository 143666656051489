.container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 49px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.loader {
  /* background-color: red; */
}
